import $ from 'jquery'

$(function() {
  $('.physiotherapist-msk-referral-form__toggle-field').each(function() {
    let toggleField = $(this).data('toggle-field');
    if($(this).val() == 'Yes' || $(this).val() == 'Other') {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });

  $('.physiotherapist-msk-referral-form__toggle-checkbox:checked').each(function() {
    let toggleField = $(this).data('toggle-field');
    if($(this).val() == 'Yes' || $(this).val() == 'Other') {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });

  $('.physiotherapist-msk-referral-form__translator-checkbox:checked').each(function() {
    let hideField = $(this).data('hide');
    let showField = $(this).data('show');
    $(hideField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    $(showField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  });


  $('.physiotherapist-msk-referral-form__telephone-consultation:checked').each(function() {
    let toggleField = $(this).data('toggle-field');
    let invertToggleField = $(this).data('invert-toggle-field');

    if($(this).val() == 'Yes') {
      $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
      $(invertToggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    } else {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
      $(invertToggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });

  $('.physiotherapist-msk-referral-form__toggle-body-part:checked').each(function() {
    let bodyPart = $(this).val().toLowerCase().replace(/ /g, '-');
    let toggleField = $('.physiotherapist-msk-referral-form__toggle-field--' + bodyPart);

    if($(this).prop('checked') == true) {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    } else {
      $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });

  $('.physiotherapist-msk-referral-form__toggle-pain-investigations:checked').each(function() {
    let toggleField = '.physiotherapist-msk-referral-form__toggle-field--pain-investigations-details';

    if($('.physiotherapist-msk-referral-form__toggle-pain-investigations:checked').length > 0) {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    } else {
      $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });

  var bodyPartWithPain = $('#combined_form_symptoms_body_part').val();

  if(bodyPartWithPain == 'Neck' || bodyPartWithPain == 'Back') {
    $('.keele-start-back-referrals').show();
  } else {
    $('.keele-start-back-referrals').hide();
  }

  $(document).on('change', '.physiotherapist-msk-referral-form__toggle-location-field', function () {
    let toggleField = $(this).data('toggle-field');
    if ($(this).prop('checked') == true) {
      $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    } else {
      $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  });
});

$(document).on('change', '#combined_form_symptoms_body_part', function() {
  var bodyPartWithPain = $(this).val();

  if(bodyPartWithPain == 'Neck' || bodyPartWithPain == 'Back' || bodyPartWithPain == 'Lower back' || bodyPartWithPain == 'Mid back') {
    $('.keele-start-back-referrals').show();
    $('.keele-start-back-referral-form__field input[type=radio]').prop('checked', false)
  } else {
    $('.keele-start-back-referrals').hide();
    $('.keele-start-back-referral-form__field input[type=radio]').prop('checked', true);
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__toggle-checkbox', function() {
  let toggleField = $(this).data('toggle-field');
  let invertField = $(this).data('invert-toggle-field');

  if(($(this).val() == 'Yes' || $(this).val() == 'Other') && $(this).prop('checked') == true) {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');

    if(invertField) {
      $(invertField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  } else {
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');

    if(invertField) {
      $(invertField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    }
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__translator-checkbox', function() {
  let hideField = $(this).data('hide');
  let showField = $(this).data('show');
  $(hideField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  $(showField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
});

$(document).on('change', '.physiotherapist-msk-referral-form__telephone-consultation', function() {
  let toggleField = $(this).data('toggle-field');
  let invertToggleField = $(this).data('invert-toggle-field');

  if(($(this).val() == 'Yes') && $(this).prop('checked') == true) {
    $(toggleField).find('input[type="radio"]').prop('checked', false);
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    $(invertToggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  } else {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
    $(invertToggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  }
});


$(document).on('change', '.physiotherapist-msk-referral-form__toggle-field', function() {
  let toggleField = $(this).data('toggle-field');
  if($(this).val() == 'Yes' || $(this).val() == 'Other') {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  } else {
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__toggle-location-field', function() {
  let toggleField = $(this).data('toggle-field');
  if ($(this).prop('checked') == true) {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  } else {
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__toggle-body-part', function() {
  let bodyPart = $(this).val().toLowerCase().replace(/ /g, '-');
  let toggleField = $('.physiotherapist-msk-referral-form__toggle-field--' + bodyPart);

  if($(this).prop('checked') == true) {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  } else {
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__toggle-pain-investigations', function() {
  let toggleField = '.physiotherapist-msk-referral-form__toggle-field--pain-investigations-details';

  if($('.physiotherapist-msk-referral-form__toggle-pain-investigations:checked').length > 0) {
    $(toggleField).removeClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  } else {
    $(toggleField).addClass('physiotherapist-msk-referral-form__toggle-field--hidden');
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__prevention-checkbox', function() {
  var preventionCount = $('.physiotherapist-msk-referral-form__prevention-checkbox--prevention:checked').length;

  if(preventionCount >= 1) {
    console.log('Disabled: preexisting health conditions');
    // $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').attr('disabled', 'disabled');
    $('.inline-modal--nhs-111').show();
  } else if (preventionCount == 0) {
    console.log('Enabled: no preexisting health conditions');
    $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').removeAttr('disabled');
  }
});

$(document).on('change', '.physiotherapist-msk-referral-form__registered-with-east-riding-general-practitioner', function() {
  var val = $(this).val();

  if(val == 'No') {
    console.log('Disabled: not East Riding GP');
    // $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').attr('disabled', 'disabled');
    $('.inline-modal--registered-with-east-riding-general-practitioner').show();
  } else if (val == 'Yes') {
    console.log('Enabled: East Riding GP');
    $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').removeAttr('disabled');
  }
});

$(document).on('click', '.physiotherapist-msk-referral-form-prevention-remove', function() {
  $('.physiotherapist-msk-referral-form__prevention-checkbox--prevention:checked').prop('checked', false);
  $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').removeAttr('disabled');
});


$(document).on('change', '.js--age-restricted', function() {
  var fieldValue = $(this).val();

  if(fieldValue) {
    var age18 = new Date();
    age18.setFullYear(age18.getFullYear() - 18);
    var dob = Date.parse(fieldValue);

    if(dob < age18) {
      console.log('Enabled: >= 18');
      $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').removeAttr('disabled');
    } else {
      console.log('Disabled: not over 18');
      $('.inline-modal--registered-with-east-riding-general-practitioner').show();
      // $('.physiotherapist-msk-referral-form__submit, .combined-form__submit').attr('disabled', 'disabled');
    }
  }
});
