document.addEventListener('click', function(event) {
  var nhsHelpline = document.querySelector('.body-part-nhs-helpline');
  var anchorClick = event.target.classList.contains("page-sidebar-navigation__link--anchor");

  if(nhsHelpline) {
    var symptomsModal = nhsHelpline.classList.contains("body-part-nhs-helpline--toggled");

    if(anchorClick && symptomsModal) {
      document.querySelector('.body-part-nhs-helpline-toggle').click();
    }
  }
});
