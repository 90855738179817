import $ from 'jquery';
import 'slick-carousel';

// https://github.com/rails/webpacker/blob/master/docs/css.md#resolve-url-loader
// yarn add resolve-url-loader
//import 'slick-carousel/slick/slick.scss';
//import 'slick-carousel/slick/slick-theme.scss';

function turbolinksCheck() {
  return (typeof Turbolinks == 'undefined') ? 'DOMContentLoaded' : 'turbolinks:load';
}

let initializer = turbolinksCheck();

function getSlickyWithIt() {
  if($('.home-information-testimonials__slick').length) {
    $('.home-information-testimonials__slick').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'home-information-testimonials-dots'
    });
  }

  if($('.aside-testimonials__slick').length) {
    $('.aside-testimonials__slick').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'aside-testimonials-dots'
    });
  }

  if($('.page-cta-testimonials__slick').length) {
    $('.page-cta-testimonials__slick').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'page-cta-testimonials-dots'
    });
  }

  if($('.testimonials-index').length) {
    $('.testimonials-index').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'testimonials-index-dots'
    });
  }
}

document.addEventListener(
  initializer,
  getSlickyWithIt
);
